<template>
  <div>
    <div class="category">
      <div class="left scrollbar-none" style=" position: fixed;top:calc(100px + 11px);bottom: 65px;">
        <van-sidebar v-model="activeKey" style="overflow-y:auto;">
          <van-sidebar-item
            v-for="(cate,index) in categories"
            :key="index"
            @click="toCategory(cate.code)"
          >
          <template #title>
            {{cate.label}}<br>
            {{cate.labelEn}}
          </template>
          </van-sidebar-item>
        </van-sidebar>
      </div>
      <div class="right" style="position:relative;top:0px;">
        <category-bar :cateId="cateId"></category-bar>
      </div>
    </div>
  </div>
</template>
<script>
import { categoryList } from '@/api/category'
import CategoryBar from './CategoryBar.vue'
export default {
  name: 'category',
  components:{
    CategoryBar
  },
  data() {
    return {
      activeKey: 0,
      categories: [],
      cateId:'',
    }
  },
  created() {
      categoryList().then(data => {
        this.categories = data.data.list;
        this.categories.unshift({code: "Hot",label: "特惠",labelEn: "Offer"})
        if(sessionStorage.getItem('path') && this.categories.filter(x =>x.code == sessionStorage.getItem('path')).length > 0 ){
          this.cateId = sessionStorage.getItem('path');
        }else {
          this.cateId = this.categories[0].code;
          sessionStorage.setItem('path',this.categories[0].code)
        }
        this.categories.forEach((element,index) => {
          if(element.code == this.cateId) {
            this.activeKey = index;
          }
        });
        // // // 向第一个主分类下的子分类页面跳转
        this.$router.push('/category/sub/' + this.cateId)
      }).catch(() => { })
  },
  methods: {
    toCategory(id) {
      sessionStorage.setItem('path', id)
      this.cateId = id;
      this.$router.push({
        path: '/category/sub/' + id
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-wrap: wrap;
  width:100%;
  .left {
    // position: fixed;
    // top:190px;
    // bottom: 0;
    overflow-y: scroll;
    width: 30%;
    .van-sidebar {
      width: 100%;
      a {
        min-height: 90px;
        // line-height: 15px;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .right {
    flex: 0 1 auto;
    background-color: #f2f3f5;//rgba(0, 0, 0, 0.5);
    // position: inherit;
    // margin-top: 98px;
    width:70%;
    margin-left: 30%;
  }
}
.van-sidebar-item {
  background-color: #f2f3f5;
  font-size: 16px;
  width: 100%;
}
.van-sidebar-item--select {
   background-color: #fff;
}

.van-sidebar-item--select::before {
  background-color: #67B346;
  height: 100%;
}

.scrollbar-none::-webkit-scrollbar {/*滚动条宽度设置*/
    width: 0px;height: 0;
}
</style>
